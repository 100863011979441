var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

exports = function () {
  "use strict";
  /*
   * Generated by PEG.js 0.9.0.
   *
   * http://pegjs.org/
   */

  function peg$subclass(child, parent) {
    function ctor() {
      (this || _global).constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
  }

  function peg$SyntaxError(message, expected, found, location) {
    (this || _global).message = message;
    (this || _global).expected = expected;
    (this || _global).found = found;
    (this || _global).location = location;
    (this || _global).name = "SyntaxError";

    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this || _global, peg$SyntaxError);
    }
  }

  peg$subclass(peg$SyntaxError, Error);

  function peg$parse(input) {
    var options = arguments.length > 1 ? arguments[1] : {},
        parser = this || _global,
        peg$FAILED = {},
        peg$startRuleFunctions = {
      Program: peg$parseProgram
    },
        peg$startRuleFunction = peg$parseProgram,
        peg$c0 = function (statements) {
      var body = [];

      for (var i in statements) {
        var statement = statements[i][0];

        if (statement) {
          body.push(statement[1]);
        }
      }

      return {
        type: "Program",
        loc: location(),
        body: body
      };
    },
        peg$c1 = ":",
        peg$c2 = {
      type: "literal",
      value: ":",
      description: "\":\""
    },
        peg$c3 = function (id, params, resultType, body) {
      return {
        type: "FunctionDeclaration",
        loc: location(),
        id: id,
        params: params,
        body: body,
        resultType: resultType ? resultType[3] : null
      };
    },
        peg$c4 = "(",
        peg$c5 = {
      type: "literal",
      value: "(",
      description: "\"(\""
    },
        peg$c6 = ")",
        peg$c7 = {
      type: "literal",
      value: ")",
      description: "\")\""
    },
        peg$c8 = function (list) {
      return list ? list[1] : [];
    },
        peg$c9 = ",",
        peg$c10 = {
      type: "literal",
      value: ",",
      description: "\",\""
    },
        peg$c11 = function (first, rest) {
      return buildList(first, rest, 3);
    },
        peg$c12 = function (id, dataType) {
      id.dataType = dataType;
      return id;
    },
        peg$c13 = function (body) {
      return {
        type: "BlockStatement",
        loc: location(),
        body: body ? body[1] : []
      };
    },
        peg$c14 = function (first, rest) {
      var list = first ? [first] : [];

      for (var i in rest) {
        var statement = rest[i][0];

        if (statement) {
          list.push(statement[1]);
        }
      }

      return list;
    },
        peg$c15 = function (declaration) {
      return {
        type: "VariableDeclaration",
        loc: location(),
        declarations: [declaration],
        kind: "var"
      };
    },
        peg$c16 = "=",
        peg$c17 = {
      type: "literal",
      value: "=",
      description: "\"=\""
    },
        peg$c18 = function (id, init) {
      return {
        type: "VariableDeclarator",
        loc: location(),
        id: id,
        init: init
      };
    },
        peg$c19 = "[]",
        peg$c20 = {
      type: "literal",
      value: "[]",
      description: "\"[]\""
    },
        peg$c21 = function () {
      return text();
    },
        peg$c22 = "[",
        peg$c23 = {
      type: "literal",
      value: "[",
      description: "\"[\""
    },
        peg$c24 = "]",
        peg$c25 = {
      type: "literal",
      value: "]",
      description: "\"]\""
    },
        peg$c26 = function (left, indices, operator, right) {
      var loc = location();
      return {
        type: "ExpressionStatement",
        loc: loc,
        expression: {
          type: "AssignmentExpression",
          loc: loc,
          operator: operator,
          left: left,
          right: right,
          indices: indices.map(function (expr) {
            return expr[3];
          })
        }
      };
    },
        peg$c27 = "+=",
        peg$c28 = {
      type: "literal",
      value: "+=",
      description: "\"+=\""
    },
        peg$c29 = "-=",
        peg$c30 = {
      type: "literal",
      value: "-=",
      description: "\"-=\""
    },
        peg$c31 = "*=",
        peg$c32 = {
      type: "literal",
      value: "*=",
      description: "\"*=\""
    },
        peg$c33 = "//=",
        peg$c34 = {
      type: "literal",
      value: "//=",
      description: "\"//=\""
    },
        peg$c35 = "/=",
        peg$c36 = {
      type: "literal",
      value: "/=",
      description: "\"/=\""
    },
        peg$c37 = "%%=",
        peg$c38 = {
      type: "literal",
      value: "%%=",
      description: "\"%%=\""
    },
        peg$c39 = "%=",
        peg$c40 = {
      type: "literal",
      value: "%=",
      description: "\"%=\""
    },
        peg$c41 = "<<=",
        peg$c42 = {
      type: "literal",
      value: "<<=",
      description: "\"<<=\""
    },
        peg$c43 = ">>>=",
        peg$c44 = {
      type: "literal",
      value: ">>>=",
      description: "\">>>=\""
    },
        peg$c45 = ">>=",
        peg$c46 = {
      type: "literal",
      value: ">>=",
      description: "\">>=\""
    },
        peg$c47 = "&=",
        peg$c48 = {
      type: "literal",
      value: "&=",
      description: "\"&=\""
    },
        peg$c49 = "|=",
        peg$c50 = {
      type: "literal",
      value: "|=",
      description: "\"|=\""
    },
        peg$c51 = "^=",
        peg$c52 = {
      type: "literal",
      value: "^=",
      description: "\"^=\""
    },
        peg$c53 = function (test, consequent, rest, last) {
      var loc = location();
      var alternate = last ? last[3] : null;

      if (rest) {
        for (var i = rest.length - 1; i >= 0; i--) {
          var elseif = rest[i][1];
          elseif.loc.end = loc.end;
          elseif.body[0].alternate = alternate;
          alternate = elseif;
        }
      }

      return {
        type: "IfStatement",
        loc: loc,
        test: test,
        consequent: consequent,
        alternate: alternate
      };
    },
        peg$c54 = function (test, consequent) {
      var loc = location();
      return {
        type: "BlockStatement",
        loc: loc,
        body: [{
          type: "IfStatement",
          loc: loc,
          test: test,
          consequent: consequent,
          alternate: null
        }]
      };
    },
        peg$c55 = function (label, stmt) {
      if (label) {
        stmt = {
          type: "LabeledStatement",
          loc: location(),
          label: label[0],
          body: stmt
        };
      }

      return stmt;
    },
        peg$c56 = ":%",
        peg$c57 = {
      type: "literal",
      value: ":%",
      description: "\":%\""
    },
        peg$c58 = function (left, right, range, body) {
      return {
        type: "ForInStatement",
        loc: location(),
        left: left,
        right: right,
        body: body,
        operator: range ? range[1] : null,
        last: range ? range[3] : null,
        step: range && range[4] ? range[4][3] : null
      };
    },
        peg$c59 = ":<=",
        peg$c60 = {
      type: "literal",
      value: ":<=",
      description: "\":<=\""
    },
        peg$c61 = ":<",
        peg$c62 = {
      type: "literal",
      value: ":<",
      description: "\":<\""
    },
        peg$c63 = ":>=",
        peg$c64 = {
      type: "literal",
      value: ":>=",
      description: "\":>=\""
    },
        peg$c65 = ":>",
        peg$c66 = {
      type: "literal",
      value: ":>",
      description: "\":>\""
    },
        peg$c67 = function (test, body) {
      return {
        type: "WhileStatement",
        loc: location(),
        test: test,
        body: body
      };
    },
        peg$c68 = function (label) {
      return {
        type: "BreakStatement",
        loc: location(),
        label: label ? label[1] : null
      };
    },
        peg$c69 = function (label) {
      return {
        type: "ContinueStatement",
        loc: location(),
        label: label ? label[1] : null
      };
    },
        peg$c70 = function (argument) {
      return {
        type: "ReturnStatement",
        loc: location(),
        argument: argument ? argument[1] : null
      };
    },
        peg$c71 = function (expression) {
      return {
        type: "ExpressionStatement",
        loc: location(),
        expression: expression
      };
    },
        peg$c72 = function (first, rest) {
      return {
        type: "SequenceExpression",
        loc: location(),
        expressions: buildList(first, rest, 3),
        text: text()
      };
    },
        peg$c73 = "?",
        peg$c74 = {
      type: "literal",
      value: "?",
      description: "\"?\""
    },
        peg$c75 = function (test, rest) {
      if (!rest) {
        return test;
      }

      return {
        type: "ConditionalExpression",
        loc: location(),
        test: test,
        consequent: rest[3],
        alternate: rest[7]
      };
    },
        peg$c76 = "||",
        peg$c77 = {
      type: "literal",
      value: "||",
      description: "\"||\""
    },
        peg$c78 = function (first, rest) {
      return buildLeftTree("LogicalExpression", first, rest, 1, 3);
    },
        peg$c79 = "&&",
        peg$c80 = {
      type: "literal",
      value: "&&",
      description: "\"&&\""
    },
        peg$c81 = function (left, right) {
      if (!right) {
        return left;
      }

      return {
        type: "BinaryExpression",
        loc: location(),
        operator: right[1],
        left: left,
        right: right[3]
      };
    },
        peg$c82 = "==",
        peg$c83 = {
      type: "literal",
      value: "==",
      description: "\"==\""
    },
        peg$c84 = "!=",
        peg$c85 = {
      type: "literal",
      value: "!=",
      description: "\"!=\""
    },
        peg$c86 = "<=",
        peg$c87 = {
      type: "literal",
      value: "<=",
      description: "\"<=\""
    },
        peg$c88 = ">=",
        peg$c89 = {
      type: "literal",
      value: ">=",
      description: "\">=\""
    },
        peg$c90 = "<",
        peg$c91 = {
      type: "literal",
      value: "<",
      description: "\"<\""
    },
        peg$c92 = ">",
        peg$c93 = {
      type: "literal",
      value: ">",
      description: "\">\""
    },
        peg$c94 = function (first, rest) {
      return buildLeftTree("BinaryExpression", first, rest, 1, 3);
    },
        peg$c95 = "+",
        peg$c96 = {
      type: "literal",
      value: "+",
      description: "\"+\""
    },
        peg$c97 = "-",
        peg$c98 = {
      type: "literal",
      value: "-",
      description: "\"-\""
    },
        peg$c99 = "|",
        peg$c100 = {
      type: "literal",
      value: "|",
      description: "\"|\""
    },
        peg$c101 = "^",
        peg$c102 = {
      type: "literal",
      value: "^",
      description: "\"^\""
    },
        peg$c103 = "*",
        peg$c104 = {
      type: "literal",
      value: "*",
      description: "\"*\""
    },
        peg$c105 = "//",
        peg$c106 = {
      type: "literal",
      value: "//",
      description: "\"//\""
    },
        peg$c107 = "/",
        peg$c108 = {
      type: "literal",
      value: "/",
      description: "\"/\""
    },
        peg$c109 = "%%",
        peg$c110 = {
      type: "literal",
      value: "%%",
      description: "\"%%\""
    },
        peg$c111 = "%",
        peg$c112 = {
      type: "literal",
      value: "%",
      description: "\"%\""
    },
        peg$c113 = "<<",
        peg$c114 = {
      type: "literal",
      value: "<<",
      description: "\"<<\""
    },
        peg$c115 = ">>>",
        peg$c116 = {
      type: "literal",
      value: ">>>",
      description: "\">>>\""
    },
        peg$c117 = ">>",
        peg$c118 = {
      type: "literal",
      value: ">>",
      description: "\">>\""
    },
        peg$c119 = "&",
        peg$c120 = {
      type: "literal",
      value: "&",
      description: "\"&\""
    },
        peg$c121 = function (operators, argument) {
      var expr = argument;

      for (var i = operators.length - 1; i >= 0; i--) {
        expr = {
          type: "UnaryExpression",
          loc: location(),
          operator: operators[i][0],
          prefix: true,
          argument: expr
        };
      }

      return expr;
    },
        peg$c122 = "~",
        peg$c123 = {
      type: "literal",
      value: "~",
      description: "\"~\""
    },
        peg$c124 = "!",
        peg$c125 = {
      type: "literal",
      value: "!",
      description: "\"!\""
    },
        peg$c126 = "$",
        peg$c127 = {
      type: "literal",
      value: "$",
      description: "\"$\""
    },
        peg$c128 = function (object, properties) {
      if (properties.length === 0) {
        return object;
      }

      var expr = object;

      for (var i in properties) {
        var property = properties[i][3];
        expr = {
          type: "MemberExpression",
          loc: {
            start: object.loc.start,
            end: property.loc.end
          },
          object: expr,
          property: property,
          computed: true
        };
      }

      return expr;
    },
        peg$c129 = function (callee, args) {
      if (!args) {
        return callee;
      }

      return {
        type: "CallExpression",
        loc: location(),
        callee: callee,
        arguments: args[1]
      };
    },
        peg$c130 = function (expr) {
      return expr;
    },
        peg$c131 = function (callee, args) {
      return {
        type: "NewExpression",
        loc: location(),
        callee: callee,
        arguments: args.map(function (expr) {
          return expr[2];
        })
      };
    },
        peg$c132 = function (elements) {
      return {
        type: "ArrayExpression",
        loc: location(),
        elements: elements ? elements[1] : []
      };
    },
        peg$c133 = function () {
      return {
        type: "Identifier",
        loc: location(),
        name: text() + "$"
      };
    },
        peg$c134 = /^[A-Z_a-z]/,
        peg$c135 = {
      type: "class",
      value: "[A-Z_a-z]",
      description: "[A-Z_a-z]"
    },
        peg$c136 = /^[0-9]/,
        peg$c137 = {
      type: "class",
      value: "[0-9]",
      description: "[0-9]"
    },
        peg$c138 = "break",
        peg$c139 = {
      type: "literal",
      value: "break",
      description: "\"break\""
    },
        peg$c140 = "continue",
        peg$c141 = {
      type: "literal",
      value: "continue",
      description: "\"continue\""
    },
        peg$c142 = "def",
        peg$c143 = {
      type: "literal",
      value: "def",
      description: "\"def\""
    },
        peg$c144 = "elseif",
        peg$c145 = {
      type: "literal",
      value: "elseif",
      description: "\"elseif\""
    },
        peg$c146 = "else",
        peg$c147 = {
      type: "literal",
      value: "else",
      description: "\"else\""
    },
        peg$c148 = "end",
        peg$c149 = {
      type: "literal",
      value: "end",
      description: "\"end\""
    },
        peg$c150 = "for",
        peg$c151 = {
      type: "literal",
      value: "for",
      description: "\"for\""
    },
        peg$c152 = "if",
        peg$c153 = {
      type: "literal",
      value: "if",
      description: "\"if\""
    },
        peg$c154 = "in",
        peg$c155 = {
      type: "literal",
      value: "in",
      description: "\"in\""
    },
        peg$c156 = "Int",
        peg$c157 = {
      type: "literal",
      value: "Int",
      description: "\"Int\""
    },
        peg$c158 = function () {
      return text();
    },
        peg$c159 = "new",
        peg$c160 = {
      type: "literal",
      value: "new",
      description: "\"new\""
    },
        peg$c161 = "return",
        peg$c162 = {
      type: "literal",
      value: "return",
      description: "\"return\""
    },
        peg$c163 = "trace",
        peg$c164 = {
      type: "literal",
      value: "trace",
      description: "\"trace\""
    },
        peg$c165 = "var",
        peg$c166 = {
      type: "literal",
      value: "var",
      description: "\"var\""
    },
        peg$c167 = "while",
        peg$c168 = {
      type: "literal",
      value: "while",
      description: "\"while\""
    },
        peg$c169 = function () {
      return {
        type: "Literal",
        loc: location(),
        value: text()
      };
    },
        peg$c170 = "0x",
        peg$c171 = {
      type: "literal",
      value: "0x",
      description: "\"0x\""
    },
        peg$c172 = "_",
        peg$c173 = {
      type: "literal",
      value: "_",
      description: "\"_\""
    },
        peg$c174 = /^[0-9A-Fa-f]/,
        peg$c175 = {
      type: "class",
      value: "[0-9A-Fa-f]",
      description: "[0-9A-Fa-f]"
    },
        peg$c176 = "0o",
        peg$c177 = {
      type: "literal",
      value: "0o",
      description: "\"0o\""
    },
        peg$c178 = /^[0-7]/,
        peg$c179 = {
      type: "class",
      value: "[0-7]",
      description: "[0-7]"
    },
        peg$c180 = "0b",
        peg$c181 = {
      type: "literal",
      value: "0b",
      description: "\"0b\""
    },
        peg$c182 = /^[01]/,
        peg$c183 = {
      type: "class",
      value: "[01]",
      description: "[01]"
    },
        peg$c184 = " ",
        peg$c185 = {
      type: "literal",
      value: " ",
      description: "\" \""
    },
        peg$c186 = "\t",
        peg$c187 = {
      type: "literal",
      value: "\t",
      description: "\"\\t\""
    },
        peg$c188 = "\r",
        peg$c189 = {
      type: "literal",
      value: "\r",
      description: "\"\\r\""
    },
        peg$c190 = "\n",
        peg$c191 = {
      type: "literal",
      value: "\n",
      description: "\"\\n\""
    },
        peg$c192 = "#",
        peg$c193 = {
      type: "literal",
      value: "#",
      description: "\"#\""
    },
        peg$c194 = /^[^\n]/,
        peg$c195 = {
      type: "class",
      value: "[^\\n]",
      description: "[^\\n]"
    },
        peg$currPos = 0,
        peg$savedPos = 0,
        peg$posDetailsCache = [{
      line: 1,
      column: 1,
      seenCR: false
    }],
        peg$maxFailPos = 0,
        peg$maxFailExpected = [],
        peg$silentFails = 0,
        peg$result;

    if ("startRule" in options) {
      if (!(options.startRule in peg$startRuleFunctions)) {
        throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
      }

      peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
    }

    function text() {
      return input.substring(peg$savedPos, peg$currPos);
    }

    function location() {
      return peg$computeLocation(peg$savedPos, peg$currPos);
    }

    function expected(description) {
      throw peg$buildException(null, [{
        type: "other",
        description: description
      }], input.substring(peg$savedPos, peg$currPos), peg$computeLocation(peg$savedPos, peg$currPos));
    }

    function error(message) {
      throw peg$buildException(message, null, input.substring(peg$savedPos, peg$currPos), peg$computeLocation(peg$savedPos, peg$currPos));
    }

    function peg$computePosDetails(pos) {
      var details = peg$posDetailsCache[pos],
          p,
          ch;

      if (details) {
        return details;
      } else {
        p = pos - 1;

        while (!peg$posDetailsCache[p]) {
          p--;
        }

        details = peg$posDetailsCache[p];
        details = {
          line: details.line,
          column: details.column,
          seenCR: details.seenCR
        };

        while (p < pos) {
          ch = input.charAt(p);

          if (ch === "\n") {
            if (!details.seenCR) {
              details.line++;
            }

            details.column = 1;
            details.seenCR = false;
          } else if (ch === "\r" || ch === "\u2028" || ch === "\u2029") {
            details.line++;
            details.column = 1;
            details.seenCR = true;
          } else {
            details.column++;
            details.seenCR = false;
          }

          p++;
        }

        peg$posDetailsCache[pos] = details;
        return details;
      }
    }

    function peg$computeLocation(startPos, endPos) {
      var startPosDetails = peg$computePosDetails(startPos),
          endPosDetails = peg$computePosDetails(endPos);
      return {
        start: {
          offset: startPos,
          line: startPosDetails.line,
          column: startPosDetails.column
        },
        end: {
          offset: endPos,
          line: endPosDetails.line,
          column: endPosDetails.column
        }
      };
    }

    function peg$fail(expected) {
      if (peg$currPos < peg$maxFailPos) {
        return;
      }

      if (peg$currPos > peg$maxFailPos) {
        peg$maxFailPos = peg$currPos;
        peg$maxFailExpected = [];
      }

      peg$maxFailExpected.push(expected);
    }

    function peg$buildException(message, expected, found, location) {
      function cleanupExpected(expected) {
        var i = 1;
        expected.sort(function (a, b) {
          if (a.description < b.description) {
            return -1;
          } else if (a.description > b.description) {
            return 1;
          } else {
            return 0;
          }
        });

        while (i < expected.length) {
          if (expected[i - 1] === expected[i]) {
            expected.splice(i, 1);
          } else {
            i++;
          }
        }
      }

      function buildMessage(expected, found) {
        function stringEscape(s) {
          function hex(ch) {
            return ch.charCodeAt(0).toString(16).toUpperCase();
          }

          return s.replace(/\\/g, "\\\\").replace(/"/g, "\\\"").replace(/\x08/g, "\\b").replace(/\t/g, "\\t").replace(/\n/g, "\\n").replace(/\f/g, "\\f").replace(/\r/g, "\\r").replace(/[\x00-\x07\x0B\x0E\x0F]/g, function (ch) {
            return "\\x0" + hex(ch);
          }).replace(/[\x10-\x1F\x80-\xFF]/g, function (ch) {
            return "\\x" + hex(ch);
          }).replace(/[\u0100-\u0FFF]/g, function (ch) {
            return "\\u0" + hex(ch);
          }).replace(/[\u1000-\uFFFF]/g, function (ch) {
            return "\\u" + hex(ch);
          });
        }

        var expectedDescs = new Array(expected.length),
            expectedDesc,
            foundDesc,
            i;

        for (i = 0; i < expected.length; i++) {
          expectedDescs[i] = expected[i].description;
        }

        expectedDesc = expected.length > 1 ? expectedDescs.slice(0, -1).join(", ") + " or " + expectedDescs[expected.length - 1] : expectedDescs[0];
        foundDesc = found ? "\"" + stringEscape(found) + "\"" : "end of input";
        return "Expected " + expectedDesc + " but " + foundDesc + " found.";
      }

      if (expected !== null) {
        cleanupExpected(expected);
      }

      return new peg$SyntaxError(message !== null ? message : buildMessage(expected, found), expected, found, location);
    }

    function peg$parseProgram() {
      var s0, s1, s2, s3, s4, s5;
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$currPos;
      s3 = peg$currPos;
      s4 = peg$parse_();

      if (s4 !== peg$FAILED) {
        s5 = peg$parseFunctionDeclaration();

        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }

      if (s3 === peg$FAILED) {
        s3 = null;
      }

      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();

        if (s4 !== peg$FAILED) {
          s5 = peg$parseEol();

          if (s5 !== peg$FAILED) {
            s3 = [s3, s4, s5];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }

      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$currPos;
        s4 = peg$parse_();

        if (s4 !== peg$FAILED) {
          s5 = peg$parseFunctionDeclaration();

          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }

        if (s3 === peg$FAILED) {
          s3 = null;
        }

        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();

          if (s4 !== peg$FAILED) {
            s5 = peg$parseEol();

            if (s5 !== peg$FAILED) {
              s3 = [s3, s4, s5];
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }

      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c0(s1);
      }

      s0 = s1;
      return s0;
    }

    function peg$parseFunctionDeclaration() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;
      s0 = peg$currPos;
      s1 = peg$parseDefToken();

      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();

        if (s2 !== peg$FAILED) {
          s3 = peg$parseIdentifier();

          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();

            if (s4 !== peg$FAILED) {
              s5 = peg$parseFormalParameters();

              if (s5 !== peg$FAILED) {
                s6 = peg$currPos;
                s7 = peg$parse_();

                if (s7 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 58) {
                    s8 = peg$c1;
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;

                    if (peg$silentFails === 0) {
                      peg$fail(peg$c2);
                    }
                  }

                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();

                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseDataType();

                      if (s10 !== peg$FAILED) {
                        s7 = [s7, s8, s9, s10];
                        s6 = s7;
                      } else {
                        peg$currPos = s6;
                        s6 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }

                if (s6 === peg$FAILED) {
                  s6 = null;
                }

                if (s6 !== peg$FAILED) {
                  s7 = peg$parse_();

                  if (s7 !== peg$FAILED) {
                    s8 = peg$parseBlock();

                    if (s8 !== peg$FAILED) {
                      s9 = peg$parse_();

                      if (s9 !== peg$FAILED) {
                        s10 = peg$parseEndToken();

                        if (s10 !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c3(s3, s5, s6, s8);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseFormalParameters() {
      var s0, s1, s2, s3, s4;
      s0 = peg$currPos;

      if (input.charCodeAt(peg$currPos) === 40) {
        s1 = peg$c4;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c5);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parse_Eol_();

        if (s3 !== peg$FAILED) {
          s4 = peg$parseFormalParameterList();

          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 === peg$FAILED) {
          s2 = null;
        }

        if (s2 !== peg$FAILED) {
          s3 = peg$parse_Eol_();

          if (s3 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 41) {
              s4 = peg$c6;
              peg$currPos++;
            } else {
              s4 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c7);
              }
            }

            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c8(s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseFormalParameterList() {
      var s0, s1, s2, s3, s4, s5, s6, s7;
      s0 = peg$currPos;
      s1 = peg$parseFormalParameter();

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parse_Eol_();

        if (s4 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 44) {
            s5 = peg$c9;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c10);
            }
          }

          if (s5 !== peg$FAILED) {
            s6 = peg$parse_Eol_();

            if (s6 !== peg$FAILED) {
              s7 = peg$parseFormalParameter();

              if (s7 !== peg$FAILED) {
                s4 = [s4, s5, s6, s7];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }

        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parse_Eol_();

          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 44) {
              s5 = peg$c9;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c10);
              }
            }

            if (s5 !== peg$FAILED) {
              s6 = peg$parse_Eol_();

              if (s6 !== peg$FAILED) {
                s7 = peg$parseFormalParameter();

                if (s7 !== peg$FAILED) {
                  s4 = [s4, s5, s6, s7];
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c11(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseFormalParameter() {
      var s0, s1, s2, s3, s4, s5;
      s0 = peg$currPos;
      s1 = peg$parseIdentifier();

      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();

        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 58) {
            s3 = peg$c1;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c2);
            }
          }

          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();

            if (s4 !== peg$FAILED) {
              s5 = peg$parseDataType();

              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c12(s1, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseBlock() {
      var s0, s1, s2, s3, s4;
      s0 = peg$currPos;
      s1 = peg$parseEol();

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parse_();

        if (s3 !== peg$FAILED) {
          s4 = peg$parseStatementList();

          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 === peg$FAILED) {
          s2 = null;
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c13(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseStatementList() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8;
      s0 = peg$currPos;
      s1 = peg$parseStatement();

      if (s1 === peg$FAILED) {
        s1 = null;
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();

        if (s2 !== peg$FAILED) {
          s3 = peg$parseEol();

          if (s3 !== peg$FAILED) {
            s4 = [];
            s5 = peg$currPos;
            s6 = peg$currPos;
            s7 = peg$parse_();

            if (s7 !== peg$FAILED) {
              s8 = peg$parseStatement();

              if (s8 !== peg$FAILED) {
                s7 = [s7, s8];
                s6 = s7;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }

            if (s6 === peg$FAILED) {
              s6 = null;
            }

            if (s6 !== peg$FAILED) {
              s7 = peg$parse_();

              if (s7 !== peg$FAILED) {
                s8 = peg$parseEol();

                if (s8 !== peg$FAILED) {
                  s6 = [s6, s7, s8];
                  s5 = s6;
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }

            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$currPos;
              s6 = peg$currPos;
              s7 = peg$parse_();

              if (s7 !== peg$FAILED) {
                s8 = peg$parseStatement();

                if (s8 !== peg$FAILED) {
                  s7 = [s7, s8];
                  s6 = s7;
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }

              if (s6 === peg$FAILED) {
                s6 = null;
              }

              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();

                if (s7 !== peg$FAILED) {
                  s8 = peg$parseEol();

                  if (s8 !== peg$FAILED) {
                    s6 = [s6, s7, s8];
                    s5 = s6;
                  } else {
                    peg$currPos = s5;
                    s5 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            }

            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c14(s1, s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseStatement() {
      var s0;
      s0 = peg$parseLabeledStatement();

      if (s0 === peg$FAILED) {
        s0 = peg$parseVariableDeclaration();

        if (s0 === peg$FAILED) {
          s0 = peg$parseAssignment();

          if (s0 === peg$FAILED) {
            s0 = peg$parseExpressionStatement();

            if (s0 === peg$FAILED) {
              s0 = peg$parseIfStatement();

              if (s0 === peg$FAILED) {
                s0 = peg$parseBreakStatement();

                if (s0 === peg$FAILED) {
                  s0 = peg$parseContinueStatement();

                  if (s0 === peg$FAILED) {
                    s0 = peg$parseReturnStatement();

                    if (s0 === peg$FAILED) {
                      s0 = peg$parseTraceStatement();
                    }
                  }
                }
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parseVariableDeclaration() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;
      s1 = peg$parseVarToken();

      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();

        if (s2 !== peg$FAILED) {
          s3 = peg$parseVariableDeclarator();

          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c15(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseVariableDeclarator() {
      var s0, s1, s2, s3, s4, s5;
      s0 = peg$currPos;
      s1 = peg$parseIdentifier();

      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();

        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 61) {
            s3 = peg$c16;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c17);
            }
          }

          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();

            if (s4 !== peg$FAILED) {
              s5 = peg$parseConditionalExpression();

              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c18(s1, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseDataType() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;
      s1 = peg$parseIntToken();

      if (s1 !== peg$FAILED) {
        s2 = [];

        if (input.substr(peg$currPos, 2) === peg$c19) {
          s3 = peg$c19;
          peg$currPos += 2;
        } else {
          s3 = peg$FAILED;

          if (peg$silentFails === 0) {
            peg$fail(peg$c20);
          }
        }

        while (s3 !== peg$FAILED) {
          s2.push(s3);

          if (input.substr(peg$currPos, 2) === peg$c19) {
            s3 = peg$c19;
            peg$currPos += 2;
          } else {
            s3 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c20);
            }
          }
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c21();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseAssignment() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;
      s0 = peg$currPos;
      s1 = peg$parseIdentifier();

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parse_();

        if (s4 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 91) {
            s5 = peg$c22;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c23);
            }
          }

          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();

            if (s6 !== peg$FAILED) {
              s7 = peg$parseConditionalExpression();

              if (s7 !== peg$FAILED) {
                s8 = peg$parse_();

                if (s8 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 93) {
                    s9 = peg$c24;
                    peg$currPos++;
                  } else {
                    s9 = peg$FAILED;

                    if (peg$silentFails === 0) {
                      peg$fail(peg$c25);
                    }
                  }

                  if (s9 !== peg$FAILED) {
                    s4 = [s4, s5, s6, s7, s8, s9];
                    s3 = s4;
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }

        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parse_();

          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 91) {
              s5 = peg$c22;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c23);
              }
            }

            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();

              if (s6 !== peg$FAILED) {
                s7 = peg$parseConditionalExpression();

                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();

                  if (s8 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 93) {
                      s9 = peg$c24;
                      peg$currPos++;
                    } else {
                      s9 = peg$FAILED;

                      if (peg$silentFails === 0) {
                        peg$fail(peg$c25);
                      }
                    }

                    if (s9 !== peg$FAILED) {
                      s4 = [s4, s5, s6, s7, s8, s9];
                      s3 = s4;
                    } else {
                      peg$currPos = s3;
                      s3 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }

        if (s2 !== peg$FAILED) {
          s3 = peg$parse_();

          if (s3 !== peg$FAILED) {
            s4 = peg$parseAssignmentOperator();

            if (s4 !== peg$FAILED) {
              s5 = peg$parse_();

              if (s5 !== peg$FAILED) {
                s6 = peg$parseConditionalExpression();

                if (s6 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c26(s1, s2, s4, s6);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseAssignmentOperator() {
      var s0;

      if (input.charCodeAt(peg$currPos) === 61) {
        s0 = peg$c16;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c17);
        }
      }

      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c27) {
          s0 = peg$c27;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;

          if (peg$silentFails === 0) {
            peg$fail(peg$c28);
          }
        }

        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c29) {
            s0 = peg$c29;
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c30);
            }
          }

          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c31) {
              s0 = peg$c31;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c32);
              }
            }

            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 3) === peg$c33) {
                s0 = peg$c33;
                peg$currPos += 3;
              } else {
                s0 = peg$FAILED;

                if (peg$silentFails === 0) {
                  peg$fail(peg$c34);
                }
              }

              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c35) {
                  s0 = peg$c35;
                  peg$currPos += 2;
                } else {
                  s0 = peg$FAILED;

                  if (peg$silentFails === 0) {
                    peg$fail(peg$c36);
                  }
                }

                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c37) {
                    s0 = peg$c37;
                    peg$currPos += 3;
                  } else {
                    s0 = peg$FAILED;

                    if (peg$silentFails === 0) {
                      peg$fail(peg$c38);
                    }
                  }

                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c39) {
                      s0 = peg$c39;
                      peg$currPos += 2;
                    } else {
                      s0 = peg$FAILED;

                      if (peg$silentFails === 0) {
                        peg$fail(peg$c40);
                      }
                    }

                    if (s0 === peg$FAILED) {
                      if (input.substr(peg$currPos, 3) === peg$c41) {
                        s0 = peg$c41;
                        peg$currPos += 3;
                      } else {
                        s0 = peg$FAILED;

                        if (peg$silentFails === 0) {
                          peg$fail(peg$c42);
                        }
                      }

                      if (s0 === peg$FAILED) {
                        if (input.substr(peg$currPos, 4) === peg$c43) {
                          s0 = peg$c43;
                          peg$currPos += 4;
                        } else {
                          s0 = peg$FAILED;

                          if (peg$silentFails === 0) {
                            peg$fail(peg$c44);
                          }
                        }

                        if (s0 === peg$FAILED) {
                          if (input.substr(peg$currPos, 3) === peg$c45) {
                            s0 = peg$c45;
                            peg$currPos += 3;
                          } else {
                            s0 = peg$FAILED;

                            if (peg$silentFails === 0) {
                              peg$fail(peg$c46);
                            }
                          }

                          if (s0 === peg$FAILED) {
                            if (input.substr(peg$currPos, 2) === peg$c47) {
                              s0 = peg$c47;
                              peg$currPos += 2;
                            } else {
                              s0 = peg$FAILED;

                              if (peg$silentFails === 0) {
                                peg$fail(peg$c48);
                              }
                            }

                            if (s0 === peg$FAILED) {
                              if (input.substr(peg$currPos, 2) === peg$c49) {
                                s0 = peg$c49;
                                peg$currPos += 2;
                              } else {
                                s0 = peg$FAILED;

                                if (peg$silentFails === 0) {
                                  peg$fail(peg$c50);
                                }
                              }

                              if (s0 === peg$FAILED) {
                                if (input.substr(peg$currPos, 2) === peg$c51) {
                                  s0 = peg$c51;
                                  peg$currPos += 2;
                                } else {
                                  s0 = peg$FAILED;

                                  if (peg$silentFails === 0) {
                                    peg$fail(peg$c52);
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parseIfStatement() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;
      s0 = peg$currPos;
      s1 = peg$parseIfToken();

      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();

        if (s2 !== peg$FAILED) {
          s3 = peg$parseConditionalExpression();

          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();

            if (s4 !== peg$FAILED) {
              s5 = peg$parseBlock();

              if (s5 !== peg$FAILED) {
                s6 = [];
                s7 = peg$currPos;
                s8 = peg$parse_();

                if (s8 !== peg$FAILED) {
                  s9 = peg$parseIfStatementElseIf();

                  if (s9 !== peg$FAILED) {
                    s8 = [s8, s9];
                    s7 = s8;
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }

                while (s7 !== peg$FAILED) {
                  s6.push(s7);
                  s7 = peg$currPos;
                  s8 = peg$parse_();

                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseIfStatementElseIf();

                    if (s9 !== peg$FAILED) {
                      s8 = [s8, s9];
                      s7 = s8;
                    } else {
                      peg$currPos = s7;
                      s7 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                }

                if (s6 !== peg$FAILED) {
                  s7 = peg$currPos;
                  s8 = peg$parse_();

                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseElseToken();

                    if (s9 !== peg$FAILED) {
                      s10 = peg$parse_();

                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseBlock();

                        if (s11 !== peg$FAILED) {
                          s8 = [s8, s9, s10, s11];
                          s7 = s8;
                        } else {
                          peg$currPos = s7;
                          s7 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s7;
                        s7 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s7;
                      s7 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }

                  if (s7 === peg$FAILED) {
                    s7 = null;
                  }

                  if (s7 !== peg$FAILED) {
                    s8 = peg$parse_();

                    if (s8 !== peg$FAILED) {
                      s9 = peg$parseEndToken();

                      if (s9 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c53(s3, s5, s6, s7);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseIfStatementElseIf() {
      var s0, s1, s2, s3, s4, s5;
      s0 = peg$currPos;
      s1 = peg$parseElseIfToken();

      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();

        if (s2 !== peg$FAILED) {
          s3 = peg$parseConditionalExpression();

          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();

            if (s4 !== peg$FAILED) {
              s5 = peg$parseBlock();

              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c54(s3, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseLabeledStatement() {
      var s0, s1, s2, s3, s4;
      s0 = peg$currPos;
      s1 = peg$currPos;
      s2 = peg$parseIdentifier();

      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 58) {
          s3 = peg$c1;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;

          if (peg$silentFails === 0) {
            peg$fail(peg$c2);
          }
        }

        if (s3 !== peg$FAILED) {
          s4 = peg$parse_Eol_();

          if (s4 !== peg$FAILED) {
            s2 = [s2, s3, s4];
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }

      if (s1 === peg$FAILED) {
        s1 = null;
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$parseForInStatement();

        if (s2 === peg$FAILED) {
          s2 = peg$parseWhileStatement();
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c55(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseForInStatement() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17;
      s0 = peg$currPos;
      s1 = peg$parseForToken();

      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();

        if (s2 !== peg$FAILED) {
          s3 = peg$parseIdentifier();

          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();

            if (s4 !== peg$FAILED) {
              s5 = peg$parseInToken();

              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();

                if (s6 !== peg$FAILED) {
                  s7 = peg$parseConditionalExpression();

                  if (s7 !== peg$FAILED) {
                    s8 = peg$currPos;
                    s9 = peg$parse_();

                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseRangeOperator();

                      if (s10 !== peg$FAILED) {
                        s11 = peg$parse_();

                        if (s11 !== peg$FAILED) {
                          s12 = peg$parseConditionalExpression();

                          if (s12 !== peg$FAILED) {
                            s13 = peg$currPos;
                            s14 = peg$parse_();

                            if (s14 !== peg$FAILED) {
                              if (input.substr(peg$currPos, 2) === peg$c56) {
                                s15 = peg$c56;
                                peg$currPos += 2;
                              } else {
                                s15 = peg$FAILED;

                                if (peg$silentFails === 0) {
                                  peg$fail(peg$c57);
                                }
                              }

                              if (s15 !== peg$FAILED) {
                                s16 = peg$parse_();

                                if (s16 !== peg$FAILED) {
                                  s17 = peg$parseConditionalExpression();

                                  if (s17 !== peg$FAILED) {
                                    s14 = [s14, s15, s16, s17];
                                    s13 = s14;
                                  } else {
                                    peg$currPos = s13;
                                    s13 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s13;
                                  s13 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s13;
                                s13 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s13;
                              s13 = peg$FAILED;
                            }

                            if (s13 === peg$FAILED) {
                              s13 = null;
                            }

                            if (s13 !== peg$FAILED) {
                              s9 = [s9, s10, s11, s12, s13];
                              s8 = s9;
                            } else {
                              peg$currPos = s8;
                              s8 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s8;
                            s8 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s8;
                          s8 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s8;
                        s8 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s8;
                      s8 = peg$FAILED;
                    }

                    if (s8 === peg$FAILED) {
                      s8 = null;
                    }

                    if (s8 !== peg$FAILED) {
                      s9 = peg$parse_();

                      if (s9 !== peg$FAILED) {
                        s10 = peg$parseBlock();

                        if (s10 !== peg$FAILED) {
                          s11 = peg$parse_();

                          if (s11 !== peg$FAILED) {
                            s12 = peg$parseEndToken();

                            if (s12 !== peg$FAILED) {
                              peg$savedPos = s0;
                              s1 = peg$c58(s3, s7, s8, s10);
                              s0 = s1;
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseRangeOperator() {
      var s0;

      if (input.substr(peg$currPos, 3) === peg$c59) {
        s0 = peg$c59;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c60);
        }
      }

      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c61) {
          s0 = peg$c61;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;

          if (peg$silentFails === 0) {
            peg$fail(peg$c62);
          }
        }

        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c63) {
            s0 = peg$c63;
            peg$currPos += 3;
          } else {
            s0 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c64);
            }
          }

          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c65) {
              s0 = peg$c65;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c66);
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parseWhileStatement() {
      var s0, s1, s2, s3, s4, s5, s6, s7;
      s0 = peg$currPos;
      s1 = peg$parseWhileToken();

      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();

        if (s2 !== peg$FAILED) {
          s3 = peg$parseConditionalExpression();

          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();

            if (s4 !== peg$FAILED) {
              s5 = peg$parseBlock();

              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();

                if (s6 !== peg$FAILED) {
                  s7 = peg$parseEndToken();

                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c67(s3, s5);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseBreakStatement() {
      var s0, s1, s2, s3, s4;
      s0 = peg$currPos;
      s1 = peg$parseBreakToken();

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parse_();

        if (s3 !== peg$FAILED) {
          s4 = peg$parseIdentifier();

          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 === peg$FAILED) {
          s2 = null;
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c68(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseContinueStatement() {
      var s0, s1, s2, s3, s4;
      s0 = peg$currPos;
      s1 = peg$parseContinueToken();

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parse_();

        if (s3 !== peg$FAILED) {
          s4 = peg$parseIdentifier();

          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 === peg$FAILED) {
          s2 = null;
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c69(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseReturnStatement() {
      var s0, s1, s2, s3, s4;
      s0 = peg$currPos;
      s1 = peg$parseReturnToken();

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parse_();

        if (s3 !== peg$FAILED) {
          s4 = peg$parseConditionalExpression();

          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 === peg$FAILED) {
          s2 = null;
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c70(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseTraceStatement() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;
      s1 = peg$parseTraceToken();

      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();

        if (s2 !== peg$FAILED) {
          s3 = peg$parseTraceStatementArguments();

          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c71(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseTraceStatementArguments() {
      var s0, s1, s2, s3, s4, s5, s6, s7;
      s0 = peg$currPos;
      s1 = peg$parseConditionalExpression();

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parse_();

        if (s4 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 44) {
            s5 = peg$c9;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c10);
            }
          }

          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();

            if (s6 !== peg$FAILED) {
              s7 = peg$parseConditionalExpression();

              if (s7 !== peg$FAILED) {
                s4 = [s4, s5, s6, s7];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }

        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parse_();

          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 44) {
              s5 = peg$c9;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c10);
              }
            }

            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();

              if (s6 !== peg$FAILED) {
                s7 = peg$parseConditionalExpression();

                if (s7 !== peg$FAILED) {
                  s4 = [s4, s5, s6, s7];
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c72(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseExpressionStatement() {
      var s0, s1;
      s0 = peg$currPos;
      s1 = peg$parseConditionalExpression();

      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c71(s1);
      }

      s0 = s1;
      return s0;
    }

    function peg$parseConditionalExpression() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;
      s0 = peg$currPos;
      s1 = peg$parseLogicalOrExpression();

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parse_();

        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 63) {
            s4 = peg$c73;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c74);
            }
          }

          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();

            if (s5 !== peg$FAILED) {
              s6 = peg$parseLogicalOrExpression();

              if (s6 !== peg$FAILED) {
                s7 = peg$parse_();

                if (s7 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 58) {
                    s8 = peg$c1;
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;

                    if (peg$silentFails === 0) {
                      peg$fail(peg$c2);
                    }
                  }

                  if (s8 !== peg$FAILED) {
                    s9 = peg$parse_();

                    if (s9 !== peg$FAILED) {
                      s10 = peg$parseLogicalOrExpression();

                      if (s10 !== peg$FAILED) {
                        s3 = [s3, s4, s5, s6, s7, s8, s9, s10];
                        s2 = s3;
                      } else {
                        peg$currPos = s2;
                        s2 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s2;
                      s2 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s2;
                    s2 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s2;
                  s2 = peg$FAILED;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 === peg$FAILED) {
          s2 = null;
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c75(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseLogicalOrExpression() {
      var s0, s1, s2, s3, s4, s5, s6, s7;
      s0 = peg$currPos;
      s1 = peg$parseLogicalAndExpression();

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parse_();

        if (s4 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c76) {
            s5 = peg$c76;
            peg$currPos += 2;
          } else {
            s5 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c77);
            }
          }

          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();

            if (s6 !== peg$FAILED) {
              s7 = peg$parseLogicalAndExpression();

              if (s7 !== peg$FAILED) {
                s4 = [s4, s5, s6, s7];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }

        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parse_();

          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c76) {
              s5 = peg$c76;
              peg$currPos += 2;
            } else {
              s5 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c77);
              }
            }

            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();

              if (s6 !== peg$FAILED) {
                s7 = peg$parseLogicalAndExpression();

                if (s7 !== peg$FAILED) {
                  s4 = [s4, s5, s6, s7];
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c78(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseLogicalAndExpression() {
      var s0, s1, s2, s3, s4, s5, s6, s7;
      s0 = peg$currPos;
      s1 = peg$parseRelationalExpression();

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parse_();

        if (s4 !== peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c79) {
            s5 = peg$c79;
            peg$currPos += 2;
          } else {
            s5 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c80);
            }
          }

          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();

            if (s6 !== peg$FAILED) {
              s7 = peg$parseRelationalExpression();

              if (s7 !== peg$FAILED) {
                s4 = [s4, s5, s6, s7];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }

        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parse_();

          if (s4 !== peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c79) {
              s5 = peg$c79;
              peg$currPos += 2;
            } else {
              s5 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c80);
              }
            }

            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();

              if (s6 !== peg$FAILED) {
                s7 = peg$parseRelationalExpression();

                if (s7 !== peg$FAILED) {
                  s4 = [s4, s5, s6, s7];
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c78(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseRelationalExpression() {
      var s0, s1, s2, s3, s4, s5, s6;
      s0 = peg$currPos;
      s1 = peg$parseAdditiveExpression();

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parse_();

        if (s3 !== peg$FAILED) {
          s4 = peg$parseRelationalOperator();

          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();

            if (s5 !== peg$FAILED) {
              s6 = peg$parseAdditiveExpression();

              if (s6 !== peg$FAILED) {
                s3 = [s3, s4, s5, s6];
                s2 = s3;
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 === peg$FAILED) {
          s2 = null;
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c81(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseRelationalOperator() {
      var s0;

      if (input.substr(peg$currPos, 2) === peg$c82) {
        s0 = peg$c82;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c83);
        }
      }

      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c84) {
          s0 = peg$c84;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;

          if (peg$silentFails === 0) {
            peg$fail(peg$c85);
          }
        }

        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c86) {
            s0 = peg$c86;
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c87);
            }
          }

          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c88) {
              s0 = peg$c88;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c89);
              }
            }

            if (s0 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 60) {
                s0 = peg$c90;
                peg$currPos++;
              } else {
                s0 = peg$FAILED;

                if (peg$silentFails === 0) {
                  peg$fail(peg$c91);
                }
              }

              if (s0 === peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 62) {
                  s0 = peg$c92;
                  peg$currPos++;
                } else {
                  s0 = peg$FAILED;

                  if (peg$silentFails === 0) {
                    peg$fail(peg$c93);
                  }
                }
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parseAdditiveExpression() {
      var s0, s1, s2, s3, s4, s5, s6, s7;
      s0 = peg$currPos;
      s1 = peg$parseMultiplicativeExpression();

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parse_();

        if (s4 !== peg$FAILED) {
          s5 = peg$parseAdditiveOperator();

          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();

            if (s6 !== peg$FAILED) {
              s7 = peg$parseMultiplicativeExpression();

              if (s7 !== peg$FAILED) {
                s4 = [s4, s5, s6, s7];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }

        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parse_();

          if (s4 !== peg$FAILED) {
            s5 = peg$parseAdditiveOperator();

            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();

              if (s6 !== peg$FAILED) {
                s7 = peg$parseMultiplicativeExpression();

                if (s7 !== peg$FAILED) {
                  s4 = [s4, s5, s6, s7];
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c94(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseAdditiveOperator() {
      var s0;

      if (input.charCodeAt(peg$currPos) === 43) {
        s0 = peg$c95;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c96);
        }
      }

      if (s0 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 45) {
          s0 = peg$c97;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;

          if (peg$silentFails === 0) {
            peg$fail(peg$c98);
          }
        }

        if (s0 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 124) {
            s0 = peg$c99;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c100);
            }
          }

          if (s0 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 94) {
              s0 = peg$c101;
              peg$currPos++;
            } else {
              s0 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c102);
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parseMultiplicativeExpression() {
      var s0, s1, s2, s3, s4, s5, s6, s7;
      s0 = peg$currPos;
      s1 = peg$parseUnaryExpression();

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parse_();

        if (s4 !== peg$FAILED) {
          s5 = peg$parseMultiplicativeOperator();

          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();

            if (s6 !== peg$FAILED) {
              s7 = peg$parseUnaryExpression();

              if (s7 !== peg$FAILED) {
                s4 = [s4, s5, s6, s7];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }

        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parse_();

          if (s4 !== peg$FAILED) {
            s5 = peg$parseMultiplicativeOperator();

            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();

              if (s6 !== peg$FAILED) {
                s7 = peg$parseUnaryExpression();

                if (s7 !== peg$FAILED) {
                  s4 = [s4, s5, s6, s7];
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c94(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseMultiplicativeOperator() {
      var s0;

      if (input.charCodeAt(peg$currPos) === 42) {
        s0 = peg$c103;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c104);
        }
      }

      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c105) {
          s0 = peg$c105;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;

          if (peg$silentFails === 0) {
            peg$fail(peg$c106);
          }
        }

        if (s0 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 47) {
            s0 = peg$c107;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c108);
            }
          }

          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c109) {
              s0 = peg$c109;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c110);
              }
            }

            if (s0 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 37) {
                s0 = peg$c111;
                peg$currPos++;
              } else {
                s0 = peg$FAILED;

                if (peg$silentFails === 0) {
                  peg$fail(peg$c112);
                }
              }

              if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c113) {
                  s0 = peg$c113;
                  peg$currPos += 2;
                } else {
                  s0 = peg$FAILED;

                  if (peg$silentFails === 0) {
                    peg$fail(peg$c114);
                  }
                }

                if (s0 === peg$FAILED) {
                  if (input.substr(peg$currPos, 3) === peg$c115) {
                    s0 = peg$c115;
                    peg$currPos += 3;
                  } else {
                    s0 = peg$FAILED;

                    if (peg$silentFails === 0) {
                      peg$fail(peg$c116);
                    }
                  }

                  if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c117) {
                      s0 = peg$c117;
                      peg$currPos += 2;
                    } else {
                      s0 = peg$FAILED;

                      if (peg$silentFails === 0) {
                        peg$fail(peg$c118);
                      }
                    }

                    if (s0 === peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 38) {
                        s0 = peg$c119;
                        peg$currPos++;
                      } else {
                        s0 = peg$FAILED;

                        if (peg$silentFails === 0) {
                          peg$fail(peg$c120);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parseUnaryExpression() {
      var s0, s1, s2, s3, s4;
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$currPos;
      s3 = peg$parseUnaryOperator();

      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();

        if (s4 !== peg$FAILED) {
          s3 = [s3, s4];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }

      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$parseUnaryOperator();

        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();

          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$parseMemberExpression();

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c121(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseUnaryOperator() {
      var s0, s1, s2, s3, s4;
      s0 = peg$currPos;
      s1 = peg$currPos;

      if (input.charCodeAt(peg$currPos) === 45) {
        s2 = peg$c97;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c98);
        }
      }

      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        peg$silentFails++;

        if (input.charCodeAt(peg$currPos) === 45) {
          s4 = peg$c97;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;

          if (peg$silentFails === 0) {
            peg$fail(peg$c98);
          }
        }

        peg$silentFails--;

        if (s4 === peg$FAILED) {
          s3 = void 0;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }

        if (s3 !== peg$FAILED) {
          s2 = [s2, s3];
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }

      if (s1 !== peg$FAILED) {
        s0 = input.substring(s0, peg$currPos);
      } else {
        s0 = s1;
      }

      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$currPos;

        if (input.charCodeAt(peg$currPos) === 43) {
          s2 = peg$c95;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;

          if (peg$silentFails === 0) {
            peg$fail(peg$c96);
          }
        }

        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          peg$silentFails++;

          if (input.charCodeAt(peg$currPos) === 43) {
            s4 = peg$c95;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c96);
            }
          }

          peg$silentFails--;

          if (s4 === peg$FAILED) {
            s3 = void 0;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }

          if (s3 !== peg$FAILED) {
            s2 = [s2, s3];
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }

        if (s1 !== peg$FAILED) {
          s0 = input.substring(s0, peg$currPos);
        } else {
          s0 = s1;
        }

        if (s0 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 126) {
            s0 = peg$c122;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c123);
            }
          }

          if (s0 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 33) {
              s0 = peg$c124;
              peg$currPos++;
            } else {
              s0 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c125);
              }
            }

            if (s0 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 36) {
                s0 = peg$c126;
                peg$currPos++;
              } else {
                s0 = peg$FAILED;

                if (peg$silentFails === 0) {
                  peg$fail(peg$c127);
                }
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parseMemberExpression() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;
      s0 = peg$currPos;
      s1 = peg$parseCallExpression();

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parse_();

        if (s4 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 91) {
            s5 = peg$c22;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c23);
            }
          }

          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();

            if (s6 !== peg$FAILED) {
              s7 = peg$parseConditionalExpression();

              if (s7 !== peg$FAILED) {
                s8 = peg$parse_();

                if (s8 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 93) {
                    s9 = peg$c24;
                    peg$currPos++;
                  } else {
                    s9 = peg$FAILED;

                    if (peg$silentFails === 0) {
                      peg$fail(peg$c25);
                    }
                  }

                  if (s9 !== peg$FAILED) {
                    s4 = [s4, s5, s6, s7, s8, s9];
                    s3 = s4;
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }

        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parse_();

          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 91) {
              s5 = peg$c22;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c23);
              }
            }

            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();

              if (s6 !== peg$FAILED) {
                s7 = peg$parseConditionalExpression();

                if (s7 !== peg$FAILED) {
                  s8 = peg$parse_();

                  if (s8 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 93) {
                      s9 = peg$c24;
                      peg$currPos++;
                    } else {
                      s9 = peg$FAILED;

                      if (peg$silentFails === 0) {
                        peg$fail(peg$c25);
                      }
                    }

                    if (s9 !== peg$FAILED) {
                      s4 = [s4, s5, s6, s7, s8, s9];
                      s3 = s4;
                    } else {
                      peg$currPos = s3;
                      s3 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c128(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseCallExpression() {
      var s0, s1, s2, s3, s4;
      s0 = peg$currPos;
      s1 = peg$parsePrimary();

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parse_();

        if (s3 !== peg$FAILED) {
          s4 = peg$parseArguments();

          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 === peg$FAILED) {
          s2 = null;
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c129(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseArguments() {
      var s0, s1, s2, s3, s4;
      s0 = peg$currPos;

      if (input.charCodeAt(peg$currPos) === 40) {
        s1 = peg$c4;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c5);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parse_Eol_();

        if (s3 !== peg$FAILED) {
          s4 = peg$parseArgumentList();

          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 === peg$FAILED) {
          s2 = null;
        }

        if (s2 !== peg$FAILED) {
          s3 = peg$parse_Eol_();

          if (s3 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 41) {
              s4 = peg$c6;
              peg$currPos++;
            } else {
              s4 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c7);
              }
            }

            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c8(s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseArgumentList() {
      var s0, s1, s2, s3, s4, s5, s6, s7;
      s0 = peg$currPos;
      s1 = peg$parseConditionalExpression();

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parse_Eol_();

        if (s4 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 44) {
            s5 = peg$c9;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c10);
            }
          }

          if (s5 !== peg$FAILED) {
            s6 = peg$parse_Eol_();

            if (s6 !== peg$FAILED) {
              s7 = peg$parseConditionalExpression();

              if (s7 !== peg$FAILED) {
                s4 = [s4, s5, s6, s7];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }

        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parse_Eol_();

          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 44) {
              s5 = peg$c9;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c10);
              }
            }

            if (s5 !== peg$FAILED) {
              s6 = peg$parse_Eol_();

              if (s6 !== peg$FAILED) {
                s7 = peg$parseConditionalExpression();

                if (s7 !== peg$FAILED) {
                  s4 = [s4, s5, s6, s7];
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c11(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsePrimary() {
      var s0, s1, s2, s3, s4, s5;
      s0 = peg$parseLiteral();

      if (s0 === peg$FAILED) {
        s0 = peg$parseIdentifier();

        if (s0 === peg$FAILED) {
          s0 = peg$parseNewExpression();

          if (s0 === peg$FAILED) {
            s0 = peg$parseArrayExpression();

            if (s0 === peg$FAILED) {
              s0 = peg$currPos;

              if (input.charCodeAt(peg$currPos) === 40) {
                s1 = peg$c4;
                peg$currPos++;
              } else {
                s1 = peg$FAILED;

                if (peg$silentFails === 0) {
                  peg$fail(peg$c5);
                }
              }

              if (s1 !== peg$FAILED) {
                s2 = peg$parse_();

                if (s2 !== peg$FAILED) {
                  s3 = peg$parseConditionalExpression();

                  if (s3 !== peg$FAILED) {
                    s4 = peg$parse_();

                    if (s4 !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 41) {
                        s5 = peg$c6;
                        peg$currPos++;
                      } else {
                        s5 = peg$FAILED;

                        if (peg$silentFails === 0) {
                          peg$fail(peg$c7);
                        }
                      }

                      if (s5 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c130(s3);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parseNewExpression() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;
      s0 = peg$currPos;
      s1 = peg$parseNewToken();

      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();

        if (s2 !== peg$FAILED) {
          s3 = peg$parseIntToken();

          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();

            if (s4 !== peg$FAILED) {
              s5 = [];
              s6 = peg$currPos;

              if (input.charCodeAt(peg$currPos) === 91) {
                s7 = peg$c22;
                peg$currPos++;
              } else {
                s7 = peg$FAILED;

                if (peg$silentFails === 0) {
                  peg$fail(peg$c23);
                }
              }

              if (s7 !== peg$FAILED) {
                s8 = peg$parse_Eol_();

                if (s8 !== peg$FAILED) {
                  s9 = peg$parseConditionalExpression();

                  if (s9 !== peg$FAILED) {
                    s10 = peg$parse_Eol_();

                    if (s10 !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 93) {
                        s11 = peg$c24;
                        peg$currPos++;
                      } else {
                        s11 = peg$FAILED;

                        if (peg$silentFails === 0) {
                          peg$fail(peg$c25);
                        }
                      }

                      if (s11 !== peg$FAILED) {
                        s7 = [s7, s8, s9, s10, s11];
                        s6 = s7;
                      } else {
                        peg$currPos = s6;
                        s6 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }

              if (s6 !== peg$FAILED) {
                while (s6 !== peg$FAILED) {
                  s5.push(s6);
                  s6 = peg$currPos;

                  if (input.charCodeAt(peg$currPos) === 91) {
                    s7 = peg$c22;
                    peg$currPos++;
                  } else {
                    s7 = peg$FAILED;

                    if (peg$silentFails === 0) {
                      peg$fail(peg$c23);
                    }
                  }

                  if (s7 !== peg$FAILED) {
                    s8 = peg$parse_Eol_();

                    if (s8 !== peg$FAILED) {
                      s9 = peg$parseConditionalExpression();

                      if (s9 !== peg$FAILED) {
                        s10 = peg$parse_Eol_();

                        if (s10 !== peg$FAILED) {
                          if (input.charCodeAt(peg$currPos) === 93) {
                            s11 = peg$c24;
                            peg$currPos++;
                          } else {
                            s11 = peg$FAILED;

                            if (peg$silentFails === 0) {
                              peg$fail(peg$c25);
                            }
                          }

                          if (s11 !== peg$FAILED) {
                            s7 = [s7, s8, s9, s10, s11];
                            s6 = s7;
                          } else {
                            peg$currPos = s6;
                            s6 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s6;
                          s6 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s6;
                        s6 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                }
              } else {
                s5 = peg$FAILED;
              }

              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c131(s3, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseArrayExpression() {
      var s0, s1, s2, s3, s4;
      s0 = peg$currPos;

      if (input.charCodeAt(peg$currPos) === 91) {
        s1 = peg$c22;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c23);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parse_Eol_();

        if (s3 !== peg$FAILED) {
          s4 = peg$parseArrayElementList();

          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 === peg$FAILED) {
          s2 = null;
        }

        if (s2 !== peg$FAILED) {
          s3 = peg$parse_Eol_();

          if (s3 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 93) {
              s4 = peg$c24;
              peg$currPos++;
            } else {
              s4 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c25);
              }
            }

            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c132(s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseArrayElementList() {
      var s0, s1, s2, s3, s4, s5, s6, s7;
      s0 = peg$currPos;
      s1 = peg$parseConditionalExpression();

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parse_Eol_();

        if (s4 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 44) {
            s5 = peg$c9;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c10);
            }
          }

          if (s5 !== peg$FAILED) {
            s6 = peg$parse_Eol_();

            if (s6 !== peg$FAILED) {
              s7 = peg$parseConditionalExpression();

              if (s7 !== peg$FAILED) {
                s4 = [s4, s5, s6, s7];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }

        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parse_Eol_();

          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 44) {
              s5 = peg$c9;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c10);
              }
            }

            if (s5 !== peg$FAILED) {
              s6 = peg$parse_Eol_();

              if (s6 !== peg$FAILED) {
                s7 = peg$parseConditionalExpression();

                if (s7 !== peg$FAILED) {
                  s4 = [s4, s5, s6, s7];
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }

        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$parse_Eol_();

          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 44) {
              s5 = peg$c9;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c10);
              }
            }

            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }

          if (s3 === peg$FAILED) {
            s3 = null;
          }

          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c11(s1, s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseIdentifier() {
      var s0, s1, s2;
      s0 = peg$currPos;
      s1 = peg$currPos;
      peg$silentFails++;
      s2 = peg$parseReservedWord();
      peg$silentFails--;

      if (s2 === peg$FAILED) {
        s1 = void 0;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$parseIdentifierName();

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c133();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseIdentifierName() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;
      s1 = peg$parseIdentifierStart();

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parseIdentifierPart();

        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseIdentifierPart();
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseIdentifierStart() {
      var s0;

      if (peg$c134.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c135);
        }
      }

      return s0;
    }

    function peg$parseIdentifierPart() {
      var s0;
      s0 = peg$parseIdentifierStart();

      if (s0 === peg$FAILED) {
        if (peg$c136.test(input.charAt(peg$currPos))) {
          s0 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s0 = peg$FAILED;

          if (peg$silentFails === 0) {
            peg$fail(peg$c137);
          }
        }
      }

      return s0;
    }

    function peg$parseReservedWord() {
      var s0;
      s0 = peg$parseBreakToken();

      if (s0 === peg$FAILED) {
        s0 = peg$parseContinueToken();

        if (s0 === peg$FAILED) {
          s0 = peg$parseDefToken();

          if (s0 === peg$FAILED) {
            s0 = peg$parseElseIfToken();

            if (s0 === peg$FAILED) {
              s0 = peg$parseElseToken();

              if (s0 === peg$FAILED) {
                s0 = peg$parseEndToken();

                if (s0 === peg$FAILED) {
                  s0 = peg$parseForToken();

                  if (s0 === peg$FAILED) {
                    s0 = peg$parseIfToken();

                    if (s0 === peg$FAILED) {
                      s0 = peg$parseInToken();

                      if (s0 === peg$FAILED) {
                        s0 = peg$parseIntToken();

                        if (s0 === peg$FAILED) {
                          s0 = peg$parseNewToken();

                          if (s0 === peg$FAILED) {
                            s0 = peg$parseReturnToken();

                            if (s0 === peg$FAILED) {
                              s0 = peg$parseTraceToken();

                              if (s0 === peg$FAILED) {
                                s0 = peg$parseVarToken();

                                if (s0 === peg$FAILED) {
                                  s0 = peg$parseWhileToken();
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parseBreakToken() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 5) === peg$c138) {
        s1 = peg$c138;
        peg$currPos += 5;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c139);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseIdentifierPart();
        peg$silentFails--;

        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseContinueToken() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 8) === peg$c140) {
        s1 = peg$c140;
        peg$currPos += 8;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c141);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseIdentifierPart();
        peg$silentFails--;

        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseDefToken() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 3) === peg$c142) {
        s1 = peg$c142;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c143);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseIdentifierPart();
        peg$silentFails--;

        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseElseIfToken() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 6) === peg$c144) {
        s1 = peg$c144;
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c145);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseIdentifierPart();
        peg$silentFails--;

        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseElseToken() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 4) === peg$c146) {
        s1 = peg$c146;
        peg$currPos += 4;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c147);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseIdentifierPart();
        peg$silentFails--;

        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseEndToken() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 3) === peg$c148) {
        s1 = peg$c148;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c149);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseIdentifierPart();
        peg$silentFails--;

        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseForToken() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 3) === peg$c150) {
        s1 = peg$c150;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c151);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseIdentifierPart();
        peg$silentFails--;

        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseIfToken() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 2) === peg$c152) {
        s1 = peg$c152;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c153);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseIdentifierPart();
        peg$silentFails--;

        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseInToken() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 2) === peg$c154) {
        s1 = peg$c154;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c155);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseIdentifierPart();
        peg$silentFails--;

        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseIntToken() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 3) === peg$c156) {
        s1 = peg$c156;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c157);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseIdentifierPart();
        peg$silentFails--;

        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c158();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseNewToken() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 3) === peg$c159) {
        s1 = peg$c159;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c160);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseIdentifierPart();
        peg$silentFails--;

        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseReturnToken() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 6) === peg$c161) {
        s1 = peg$c161;
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c162);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseIdentifierPart();
        peg$silentFails--;

        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseTraceToken() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 5) === peg$c163) {
        s1 = peg$c163;
        peg$currPos += 5;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c164);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseIdentifierPart();
        peg$silentFails--;

        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseVarToken() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 3) === peg$c165) {
        s1 = peg$c165;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c166);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseIdentifierPart();
        peg$silentFails--;

        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseWhileToken() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 5) === peg$c167) {
        s1 = peg$c167;
        peg$currPos += 5;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c168);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parseIdentifierPart();
        peg$silentFails--;

        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseLiteral() {
      var s0, s1;
      s0 = peg$currPos;
      s1 = peg$parseIntegerLiteral();

      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c169();
      }

      s0 = s1;
      return s0;
    }

    function peg$parseIntegerLiteral() {
      var s0;
      s0 = peg$parseHexIntegerLiteral();

      if (s0 === peg$FAILED) {
        s0 = peg$parseOctalIntegerLiteral();

        if (s0 === peg$FAILED) {
          s0 = peg$parseBinaryIntegerLiteral();

          if (s0 === peg$FAILED) {
            s0 = peg$parseDecimalIntegerLiteral();
          }
        }
      }

      return s0;
    }

    function peg$parseHexIntegerLiteral() {
      var s0, s1, s2, s3, s4, s5, s6, s7;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 2) === peg$c170) {
        s1 = peg$c170;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c171);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parseHexDigit();

        if (s3 !== peg$FAILED) {
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            s3 = peg$parseHexDigit();
          }
        } else {
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s3 = [];
          s4 = peg$currPos;

          if (input.charCodeAt(peg$currPos) === 95) {
            s5 = peg$c172;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c173);
            }
          }

          if (s5 !== peg$FAILED) {
            s6 = [];
            s7 = peg$parseHexDigit();

            if (s7 !== peg$FAILED) {
              while (s7 !== peg$FAILED) {
                s6.push(s7);
                s7 = peg$parseHexDigit();
              }
            } else {
              s6 = peg$FAILED;
            }

            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }

          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$currPos;

            if (input.charCodeAt(peg$currPos) === 95) {
              s5 = peg$c172;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c173);
              }
            }

            if (s5 !== peg$FAILED) {
              s6 = [];
              s7 = peg$parseHexDigit();

              if (s7 !== peg$FAILED) {
                while (s7 !== peg$FAILED) {
                  s6.push(s7);
                  s7 = peg$parseHexDigit();
                }
              } else {
                s6 = peg$FAILED;
              }

              if (s6 !== peg$FAILED) {
                s5 = [s5, s6];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }

          if (s3 !== peg$FAILED) {
            s1 = [s1, s2, s3];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseHexDigit() {
      var s0;

      if (peg$c174.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c175);
        }
      }

      return s0;
    }

    function peg$parseOctalIntegerLiteral() {
      var s0, s1, s2, s3, s4, s5, s6, s7;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 2) === peg$c176) {
        s1 = peg$c176;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c177);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parseOctalDigit();

        if (s3 !== peg$FAILED) {
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            s3 = peg$parseOctalDigit();
          }
        } else {
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s3 = [];
          s4 = peg$currPos;

          if (input.charCodeAt(peg$currPos) === 95) {
            s5 = peg$c172;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c173);
            }
          }

          if (s5 !== peg$FAILED) {
            s6 = [];
            s7 = peg$parseOctalDigit();

            if (s7 !== peg$FAILED) {
              while (s7 !== peg$FAILED) {
                s6.push(s7);
                s7 = peg$parseOctalDigit();
              }
            } else {
              s6 = peg$FAILED;
            }

            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }

          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$currPos;

            if (input.charCodeAt(peg$currPos) === 95) {
              s5 = peg$c172;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c173);
              }
            }

            if (s5 !== peg$FAILED) {
              s6 = [];
              s7 = peg$parseOctalDigit();

              if (s7 !== peg$FAILED) {
                while (s7 !== peg$FAILED) {
                  s6.push(s7);
                  s7 = peg$parseOctalDigit();
                }
              } else {
                s6 = peg$FAILED;
              }

              if (s6 !== peg$FAILED) {
                s5 = [s5, s6];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }

          if (s3 !== peg$FAILED) {
            s1 = [s1, s2, s3];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseOctalDigit() {
      var s0;

      if (peg$c178.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c179);
        }
      }

      return s0;
    }

    function peg$parseBinaryIntegerLiteral() {
      var s0, s1, s2, s3, s4, s5, s6, s7;
      s0 = peg$currPos;

      if (input.substr(peg$currPos, 2) === peg$c180) {
        s1 = peg$c180;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c181);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parseBinaryDigit();

        if (s3 !== peg$FAILED) {
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            s3 = peg$parseBinaryDigit();
          }
        } else {
          s2 = peg$FAILED;
        }

        if (s2 !== peg$FAILED) {
          s3 = [];
          s4 = peg$currPos;

          if (input.charCodeAt(peg$currPos) === 95) {
            s5 = peg$c172;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c173);
            }
          }

          if (s5 !== peg$FAILED) {
            s6 = [];
            s7 = peg$parseBinaryDigit();

            if (s7 !== peg$FAILED) {
              while (s7 !== peg$FAILED) {
                s6.push(s7);
                s7 = peg$parseBinaryDigit();
              }
            } else {
              s6 = peg$FAILED;
            }

            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }

          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$currPos;

            if (input.charCodeAt(peg$currPos) === 95) {
              s5 = peg$c172;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;

              if (peg$silentFails === 0) {
                peg$fail(peg$c173);
              }
            }

            if (s5 !== peg$FAILED) {
              s6 = [];
              s7 = peg$parseBinaryDigit();

              if (s7 !== peg$FAILED) {
                while (s7 !== peg$FAILED) {
                  s6.push(s7);
                  s7 = peg$parseBinaryDigit();
                }
              } else {
                s6 = peg$FAILED;
              }

              if (s6 !== peg$FAILED) {
                s5 = [s5, s6];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }

          if (s3 !== peg$FAILED) {
            s1 = [s1, s2, s3];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseBinaryDigit() {
      var s0;

      if (peg$c182.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c183);
        }
      }

      return s0;
    }

    function peg$parseDecimalIntegerLiteral() {
      var s0, s1, s2, s3, s4, s5, s6;
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parseDecimalDigit();

      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parseDecimalDigit();
        }
      } else {
        s1 = peg$FAILED;
      }

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;

        if (input.charCodeAt(peg$currPos) === 95) {
          s4 = peg$c172;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;

          if (peg$silentFails === 0) {
            peg$fail(peg$c173);
          }
        }

        if (s4 !== peg$FAILED) {
          s5 = [];
          s6 = peg$parseDecimalDigit();

          if (s6 !== peg$FAILED) {
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$parseDecimalDigit();
            }
          } else {
            s5 = peg$FAILED;
          }

          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }

        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;

          if (input.charCodeAt(peg$currPos) === 95) {
            s4 = peg$c172;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c173);
            }
          }

          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$parseDecimalDigit();

            if (s6 !== peg$FAILED) {
              while (s6 !== peg$FAILED) {
                s5.push(s6);
                s6 = peg$parseDecimalDigit();
              }
            } else {
              s5 = peg$FAILED;
            }

            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseDecimalDigit() {
      var s0;

      if (peg$c136.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c137);
        }
      }

      return s0;
    }

    function peg$parse_Eol_() {
      var s0, s1, s2, s3, s4, s5;
      s0 = peg$currPos;
      s1 = peg$parse_();

      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parseEol();

        if (s4 !== peg$FAILED) {
          s5 = peg$parse_();

          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }

        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parseEol();

          if (s4 !== peg$FAILED) {
            s5 = peg$parse_();

            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parse_() {
      var s0, s1;
      s0 = [];
      s1 = peg$parseWhiteSpace();

      while (s1 !== peg$FAILED) {
        s0.push(s1);
        s1 = peg$parseWhiteSpace();
      }

      return s0;
    }

    function peg$parseWhiteSpace() {
      var s0;

      if (input.charCodeAt(peg$currPos) === 32) {
        s0 = peg$c184;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c185);
        }
      }

      if (s0 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 9) {
          s0 = peg$c186;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;

          if (peg$silentFails === 0) {
            peg$fail(peg$c187);
          }
        }
      }

      return s0;
    }

    function peg$parseEol() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;
      s1 = peg$parseComment();

      if (s1 === peg$FAILED) {
        s1 = null;
      }

      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 13) {
          s2 = peg$c188;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;

          if (peg$silentFails === 0) {
            peg$fail(peg$c189);
          }
        }

        if (s2 === peg$FAILED) {
          s2 = null;
        }

        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 10) {
            s3 = peg$c190;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c191);
            }
          }

          if (s3 !== peg$FAILED) {
            s1 = [s1, s2, s3];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseComment() {
      var s0, s1, s2, s3;
      s0 = peg$currPos;

      if (input.charCodeAt(peg$currPos) === 35) {
        s1 = peg$c192;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;

        if (peg$silentFails === 0) {
          peg$fail(peg$c193);
        }
      }

      if (s1 !== peg$FAILED) {
        s2 = [];

        if (peg$c194.test(input.charAt(peg$currPos))) {
          s3 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s3 = peg$FAILED;

          if (peg$silentFails === 0) {
            peg$fail(peg$c195);
          }
        }

        while (s3 !== peg$FAILED) {
          s2.push(s3);

          if (peg$c194.test(input.charAt(peg$currPos))) {
            s3 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s3 = peg$FAILED;

            if (peg$silentFails === 0) {
              peg$fail(peg$c195);
            }
          }
        }

        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function buildList(first, rest, index) {
      var result = [first];

      for (var i in rest) {
        result.push(rest[i][index]);
      }

      return result;
    }

    function buildLeftTree(type, first, rest, operatorIndex, rightIndex) {
      var left = first;

      for (var i in rest) {
        var right = rest[i][rightIndex];
        left = {
          type: type,
          loc: {
            start: left.loc.start,
            end: right.loc.end
          },
          operator: rest[i][operatorIndex],
          left: left,
          right: right
        };
      }

      return left;
    }

    peg$result = peg$startRuleFunction();

    if (peg$result !== peg$FAILED && peg$currPos === input.length) {
      return peg$result;
    } else {
      if (peg$result !== peg$FAILED && peg$currPos < input.length) {
        peg$fail({
          type: "end",
          description: "end of input"
        });
      }

      throw peg$buildException(null, peg$maxFailExpected, peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null, peg$maxFailPos < input.length ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1) : peg$computeLocation(peg$maxFailPos, peg$maxFailPos));
    }
  }

  return {
    SyntaxError: peg$SyntaxError,
    parse: peg$parse
  };
}();

export default exports;