import _context from "./context";
import _converter from "./converter";
import _escodegen from "escodegen";
import _parser from "./parser";
import _vm from "vm";
var exports = {};
var Context = _context;
var converter = _converter;
var escodegen = _escodegen;
var parser = _parser;
var vm = _vm;

function parse(sourceText, options) {
  try {
    return parser.parse(sourceText, options && options.pegjs || {});
  } catch (e) {
    if (e instanceof parser.SyntaxError) {
      var err = new Error(e.message);
      err.name = "ParseError";
      err.location = e.location;
      e = err;
    }

    throw e;
  }
}

function generate(targetAst, options) {
  return "(function(){return function($){\n" + escodegen.generate(targetAst, options && options.escodegen || {}) + "\nmain$();};}());\n";
}

function run(targetText, ctx) {
  var targetFunc = vm.runInNewContext(targetText);
  targetFunc(ctx);
}

exports = {
  Context: Context,
  convert: converter.convert,
  generate: generate,
  parse: parse,
  run: run
};
export default exports;
export const convert = exports.convert;
const _Context = exports.Context;
export { _Context as Context };